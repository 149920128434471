import './src/styles/global.scss';

export const onRouteUpdate = ({ location }, { offset = 0 }) => {
  if (typeof window !== 'undefined') {
    if (typeof window.WSC === 'undefined') {
      console.log('no WSC available...');
    } else {
      const event = window.WSC.modules.instatag.eventRegister.getEvent('event1');
      event.getEvar('eVar8').value = 'Page-Path: ' + location.pathname; //set page name
      event.setOption('callSatellite', true);
      event.fire();
    }
  }
};

export const onInitialClientRender = ({ _ }, { pluginOptions }) => {
  /* script for scroll position */
  const debounce = (fn) => {
    let frame;
    return (...params) => {
      if (frame) {
        cancelAnimationFrame(frame);
      }
      frame = requestAnimationFrame(() => {
        fn(...params);
      });
    };
  };
  const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
  };
  document.addEventListener('scroll', debounce(storeScroll), { passive: true });
  storeScroll();
};
